import imageCompression from "browser-image-compression";
const production = true;
const app_name = "MBD APP";
const app_icon = window.location.origin + "/img.png";
const api_url = production
  ? "https://apimbd2022.inosis.biz"
  : "http://localhost:2020";

const getHeader = async (params = {}) => ({
  headers: {
    Authorization: await localStorage.getItem("jwt_token"),
  },
  params: params,
});

const getBase64 = async (file) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  return new Promise((resolve, reject) => {
    reader.onload = function () {
      resolve(reader.result);
    };
  });
};

const imageCompress = async (file) => {
  try {
    const options = {
      maxSizeMB: 1,
      // maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    return await imageCompression(file, options);
  } catch (error) {
    console.log(error);
  }
};

export { api_url, getHeader, app_name, app_icon, getBase64, imageCompress };
